<template>
  <v-overlay v-if="isBrowserSupported">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <v-sheet class="unsupported-browser-sheet p-2" v-else>
    <v-card 
      class="text-body-2 font-weight-5 mt-2" 
      elevation="3"
    >
      <v-card-title class="font-weight-6 primary--text">
        <v-icon left large>mdi-web</v-icon>
        Unsupported Browser
      </v-card-title>

      <v-card-text class="text-caption grey--text text--darken-3 mt-2 px-4">
        <div class="text-left">
          <v-icon x-small dense color="blue-grey">mdi-format-quote-open-outline</v-icon>
        </div>

        <div class="text-justify ml-6 mr-4">
          We're sorry, only <v-icon dense class="ml-1">mdi-google-chrome</v-icon> <small class="text-caption font-weight-bold mr-1">Chrome</small> and <v-icon dense class="ml-1">mdi-apple-safari</v-icon> <small class="text-caption font-weight-bold mr-1">Safari</small> are supported.
          
          <div class="mt-5 mb-2">Please tap <small><v-icon color="primary">mdi-dots-horizontal</v-icon></small> and choose <small class="text-caption font-weight-6 primary--text"><v-icon dense color="primary">mdi-web</v-icon> Open in browser</small> to claim your voucher.</div>

          <div class="my-2">or ...</div>

          Tap <small class="text-body-2 font-weight-6 primary--text" @click="copyToClipboard(currentUrl)">here</small> to copy the link address and then open it in <v-icon dense>mdi-google-chrome</v-icon> <small class="text-caption font-weight-bold mr-1">Chrome</small>/<v-icon dense class="ml-1">mdi-apple-safari</v-icon> <small class="text-caption font-weight-bold mr-1">Safari</small>
        </div>
        
        <div class="text-right">
          <v-icon x-small dense color="blue-grey">mdi-format-quote-close-outline</v-icon>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-list-item>
          <v-list-item-avatar class="mr-2">
            <v-img
              :alt="profile.restaurant_name"
              :src="getMerchantLogo()"
              width="40" height="40"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="text-caption blue-grey--text">{{ profile.restaurant_name }}</v-list-item-title>
          </v-list-item-content>

          <v-row
            align="center"
            justify="end"
          >
            <v-btn 
              :href="whatsAppUrl + profile.contact_phone" 
              target="_blank" 
              class="text-decoration-none mr-4 mt-1"
              icon
            >
              <v-icon color="#25D366">
                mdi-whatsapp
              </v-icon>
              <span class="text-caption font-weight-6 ml-1">Ask</span>
            </v-btn>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('auth', {
      isLoggedIn: ['isLoggedIn'],
    }),
    isBrowserSupported() {
      return !(/(.browser|.fbav|.micromessenger(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent) && (/(opera|chrome|safari|firefox|msie(?=\/))\/?\s*(\d+)/i).test(navigator.userAgent);
    },
    whatsAppUrl() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=';
    },
    currentUrl() {
      return window.location.href;
    }
  },
  methods: {
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('merchant', ['getMerchantVouchers']),
    getMerchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        alert("Copied!");
      });
    }
  },
  async mounted() {
    if (!this.$route?.params?.voucherCode) {
      this.$router.push({ name: 'home' }).catch(() => {});
    }

    this.$gtag.event('claim-voucher', {
      event_category: 'engagement',
      event_label: 'Claim Voucher',
      event_value: this.$route?.params?.voucherCode,
    });

    if (!this.isBrowserSupported) {
      this.$gtag.event('browser-not-supported-claim-voucher', {
        event_category: 'experience',
        event_label: 'Claim Voucher (Browser Not Supported)',
        event_value: this.$route?.params?.voucherCode,
      });
    } else {
      const voucher = (await this.getMerchantVouchers({
        merchantId: this.profile.merchant_id,
        params: {
          code: this.$route?.params?.voucherCode,
        },
      }))?.[0];

      if (!voucher) {
        this.$gtag.event('invalid-voucher', {
          event_category: 'experience',
          event_label: 'Invalid Voucher',
          event_value: this.$route?.params?.voucherCode,
        });
        this.$router.push({ name: 'home', query: { alert: 'invalid-voucher' } }).catch(() => {});
      }

      if (this.isLoggedIn) {
        try {
          await this.claimVoucher({
            voucherCode: this.$route?.params?.voucherCode,
            merchantId: this.profile.merchant_id,
          });
        } catch (e) {
          this.$gtag.event('error-claim-voucher', {
            event_category: 'experience',
            event_label: 'Claim Voucher (Error)',
            event_value: e.message,
          });

          alert(e.message);
        }
        
        this.$router.push({ name: 'vouchers', query: { id: voucher.id } }).catch(() => {});
      } else {
        await this.claimVoucher({
          id: voucher.id,
          code: voucher.code,
          type: voucher.type,
          item: voucher.item,
          visibility: voucher.visibility,
          amount: voucher.amount,
          quantity: voucher.limit_per_customer,
          delivery: voucher.delivery,
          pickup: voucher.pickup,
          dinein: voucher.dinein,
          expiration: voucher.expiration,
          minPurchase: voucher.min_purchase,
          fullyRedeemed: voucher.fully_redeemed,
          merchantId: this.profile.merchant_id,
          restaurantName: this.profile.restaurant_name,
          restaurantHostname: this.profile.restaurant_hostname,
          logo: this.profile.logo_s3,
          isReady: 2,
          merchantStatus: "active",
        });

        this.$router.push({ name: 'vouchers', query: { id: voucher.id } }).catch(() => {});
      }
    }
  },
};
</script>
<style scoped>
.unsupported-browser-sheet {
  margin-top: 30%;
}
</style>